"use strict";

exports.__esModule = true;
exports["default"] = void 0;

var _ramda = require("ramda");

var _isNotNil = _interopRequireDefault(require("./isNotNil"));

var _isNonEmptyArray = _interopRequireDefault(require("./isNonEmptyArray"));

var _stubUndefined = _interopRequireDefault(require("./stubUndefined"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var byArity = (0, _ramda.comparator)(function (a, b) {
  return a.length > b.length;
});
var getMaxArity = (0, _ramda.pipe)((0, _ramda.sort)(byArity), _ramda.head, (0, _ramda.prop)('length'));
var iteratorFn = (0, _ramda.curry)(function (args, accumulator, fn) {
  var result = fn.apply(void 0, _toConsumableArray(args));
  return (0, _isNotNil["default"])(result) ? (0, _ramda.reduced)(result) : accumulator;
});

var dispatchImpl = function dispatchImpl(functions) {
  var arity = getMaxArity(functions);
  return (0, _ramda.curryN)(arity, function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return (0, _ramda.reduce)(iteratorFn(args), undefined, functions);
  });
};

var dispatch = (0, _ramda.ifElse)(_isNonEmptyArray["default"], dispatchImpl, _stubUndefined["default"]);
var _default = dispatch;
exports["default"] = _default;