"use strict";

exports.__esModule = true;
exports["default"] = void 0;

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var isOfTypeObject = function isOfTypeObject(val) {
  return _typeof(val) === 'object';
};

var _default = isOfTypeObject;
exports["default"] = _default;