"use strict";

exports.__esModule = true;
exports["default"] = void 0;

var _isFunction = _interopRequireDefault(require("../../isFunction"));

var _isNotUndefined = _interopRequireDefault(require("../../isNotUndefined"));

var _String = _interopRequireDefault(require("./String.repeat"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var padEndPonyfill = function padEndPonyfill(padString, targetLength, value) {
  // eslint-disable-next-line no-bitwise
  var finalLength = targetLength >> 0;
  var finalPadString = String((0, _isNotUndefined["default"])(padString) ? padString : ' ');

  if (value.length > finalLength) {
    return String(value);
  }

  finalLength -= value.length;

  if (finalLength > finalPadString.length) {
    var remainingLength = finalLength / finalPadString.length;
    finalPadString += (0, _isFunction["default"])(String.prototype.repeat) ? finalPadString.repeat(remainingLength) : (0, _String["default"])(finalPadString, remainingLength);
  }

  return String(value) + finalPadString.slice(0, finalLength);
};

var _default = padEndPonyfill;
exports["default"] = _default;