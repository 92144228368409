"use strict";

exports.__esModule = true;
exports.promap = exports.bimap = exports.extract = exports.extend = exports.chainRec = exports.chain = exports.traverse = exports.reduce = exports.zero = exports.alt = exports.of = exports.ap = exports.contramap = exports.map = exports.empty = exports.concat = exports.id = exports.compose = exports.lte = exports.equals = void 0;
var equals = 'fantasy-land/equals';
exports.equals = equals;
var lte = 'fantasy-land/lte';
exports.lte = lte;
var compose = 'fantasy-land/compose';
exports.compose = compose;
var id = 'fantasy-land/id';
exports.id = id;
var concat = 'fantasy-land/concat';
exports.concat = concat;
var empty = 'fantasy-land/empty';
exports.empty = empty;
var map = 'fantasy-land/map';
exports.map = map;
var contramap = 'fantasy-land/contramap';
exports.contramap = contramap;
var ap = 'fantasy-land/ap';
exports.ap = ap;
var of = 'fantasy-land/of';
exports.of = of;
var alt = 'fantasy-land/alt';
exports.alt = alt;
var zero = 'fantasy-land/zero';
exports.zero = zero;
var reduce = 'fantasy-land/reduce';
exports.reduce = reduce;
var traverse = 'fantasy-land/traverse';
exports.traverse = traverse;
var chain = 'fantasy-land/chain';
exports.chain = chain;
var chainRec = 'fantasy-land/chainRec';
exports.chainRec = chainRec;
var extend = 'fantasy-land/extend';
exports.extend = extend;
var extract = 'fantasy-land/extract';
exports.extract = extract;
var bimap = 'fantasy-land/bimap';
exports.bimap = bimap;
var promap = 'fantasy-land/promap';
exports.promap = promap;