"use strict";

exports.__esModule = true;
exports["default"] = void 0;

var _ramda = require("ramda");

// helpers
var filterIndexed = (0, _ramda.addIndex)(_ramda.filter);
var containsIndex = (0, _ramda.curry)(function (indexes, val, index) {
  return (0, _ramda.contains)(index, indexes);
});
/**
 * Picks values from list by indexes.
 *
 * Note: pickIndexes will skip non existing indexes. If you want to include them
 * use ramda's `props` function.
 *
 * @func pickIndexes
 * @memberOf RA
 * @since {@link https://char0n.github.io/ramda-adjunct/1.1.0|v1.1.0}
 * @category List
 * @sig  [Number] -> [a] -> [a]
 * @param {Array} indexes The indexes to pick
 * @param {Array} list The list to pick values from
 * @return {Array} New array containing only values at `indexes`
 * @see {@link http://ramdajs.com/docs/#pick|R.pick}, {@link RA.omitIndexes|omitIndexes}
 * @example
 *
 * RA.pickIndexes([0, 2], ['a', 'b', 'c']); //=> ['a', 'c']
 */

var pickIndexes = (0, _ramda.curry)(function (indexes, list) {
  return filterIndexed(containsIndex(indexes), list);
});
var _default = pickIndexes;
exports["default"] = _default;